.hasTwoColumnSpacing{
    margin-left: 0 !important; margin-right: 0 !important;
    .columnOne{padding-left: 0;
        padding-right: 30px;
        @include mobile{
            padding-left: 15px;
            padding-right: 15px; margin-bottom: .6rem;
        }
    }
    .columnTwo{padding-right: 0;
        padding-left: 30px;
        @include mobile{
            padding-right: 15px;
            padding-left: 15px;
            margin-top: .6rem;
        }
    }
}

.negativeContainerMarginTop{
    margin-top: 0px;  position: relative; z-index: 5;
    @include mobile{margin-top: 0;}
}