@import "./variables";
%MainCarouselGradiant{
   background: getColor(primaryGreen);
   background: -moz-linear-gradient(90deg, getColor(primaryGreen) 15%, getColor(primaryYellow) 100%);
   background: -webkit-linear-gradient(90deg, getColor(primaryGreen) 15%, getColor(primaryYellow) 100%);
   background: linear-gradient(90deg, getColor(primaryGreen) 15%, getColor(primaryYellow) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#72aa1b",endColorstr="#b7ce29",GradientType=1);
  }

  %greenlGradiant-90Degree{
    background: getColor(primaryGreen) !important;
    background: -moz-linear-gradient(-90deg, getColor(primaryGreen) 15%, getColor(primaryYellow) 100%) !important;
    background: -webkit-linear-gradient(-90deg, getColor(primaryGreen) 15%, getColor(primaryYellow) 100%) !important;
    background: linear-gradient(-90deg, getColor(primaryGreen) 15%, getColor(primaryYellow) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#72aa1b",endColorstr="#b7ce29",GradientType=1);
   }

  %btnWhiteGradiant{
   background: rgb(231,235,242);
   background: -moz-linear-gradient(0deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   background: -webkit-linear-gradient(0deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   background: linear-gradient(0deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e7ebf2",endColorstr="#ffffff",GradientType=1);
  }

  %btnWhiteGradiantHover{
   background: rgb(255,255,255);
   background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%);
   background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%);
   background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e7ebf2",GradientType=1);
  }

  %whiteGradiant90Degree{
   background: rgb(231,235,242);
   background: -moz-linear-gradient(90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   background: -webkit-linear-gradient(90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   background: linear-gradient(90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e7ebf2",endColorstr="#ffffff",GradientType=1);
  }
  %blueGradiant45Degree{
    background: rgb(231,235,242);
    background: -moz-linear-gradient(90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(45deg, rgba(231,235,242,1) 0%,rgba(231,235,242,1) 20%,#6cbabb 65%, #00a9ac 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e7ebf2",endColorstr="#ffffff",GradientType=1);
   }
  %whiteGradiantMinus90Degree{
   background: rgb(231,235,242);
   background: -moz-linear-gradient(-90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   background: -webkit-linear-gradient(-90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   background: linear-gradient(-90deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e7ebf2",endColorstr="#ffffff",GradientType=1);
  }
  %whiteGradiant180Degree{
    background: rgb(231,235,242);
    background: -moz-linear-gradient(180deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(231,235,242,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e7ebf2",endColorstr="#ffffff",GradientType=1);
   }

  %purpleGradiant{
   background: rgb(73,47,106);
   background: -moz-linear-gradient(0deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: -webkit-linear-gradient(0deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: linear-gradient(0deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#492f6a",endColorstr="#7e356f",GradientType=1);
  }

  %purpleGradiantHover{
   background: rgb(73,47,106);
   background: -moz-linear-gradient(180deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: -webkit-linear-gradient(180deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: linear-gradient(180deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#492f6a",endColorstr="#7e356f",GradientType=1);
  }

  %purpleGradiant90Degree{
   background: rgb(73,47,106);
   background: -moz-linear-gradient(90deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: -webkit-linear-gradient(90deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: linear-gradient(90deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#492f6a",endColorstr="#7e356f",GradientType=1);
  }

  %purpleGradiant-90Degree{
   background: rgb(73,47,106);
   background: -moz-linear-gradient(-90deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: -webkit-linear-gradient(-90deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   background: linear-gradient(-90deg, rgba(73,47,106,1) 0%, rgba(126,53,111,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#492f6a",endColorstr="#7e356f",GradientType=1);
  }

%pinkGradiant{
   background: rgb(216,17,114);
   background: -moz-linear-gradient(0deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
   background: -webkit-linear-gradient(0deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
   background: linear-gradient(0deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d81172",endColorstr="#7e356f",GradientType=1);
}
%pinkGradiantHover{
   background: rgb(216,17,114);
   background: -moz-linear-gradient(180deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
   background: -webkit-linear-gradient(180deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
   background: linear-gradient(180deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d81172",endColorstr="#7e356f",GradientType=1);
}
%pinkGradiant-90Degree{
  background: rgb(216,17,114);
  background: -moz-linear-gradient(-90deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
  background: -webkit-linear-gradient(-90deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
  background: linear-gradient(-90deg, rgba(216,17,114,1) 0%, rgba(126,53,111,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d81172",endColorstr="#7e356f",GradientType=1);
}

  %backdropPurpel{
   background: rgb(73,47,106);
   background: -moz-linear-gradient(180deg, rgba(73,47,106,1) 0%, rgba(73,47,106,0) 100%);
   background: -webkit-linear-gradient(180deg, rgba(73,47,106,1) 0%, rgba(73,47,106,0) 100%);
   background: linear-gradient(180deg, rgba(73,47,106,1) 0%, rgba(73,47,106,0) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#492f6a",endColorstr="#492f6a",GradientType=1);
  }
  %backdropPurpel90Degree{
   background: rgb(73,47,106);
   background: -moz-linear-gradient(-90deg, rgba(73,47,106,1) 0%, rgba(73,47,106,0) 100%);
   background: -webkit-linear-gradient(-90deg, rgba(73,47,106,1) 0%, rgba(73,47,106,0) 100%);
   background: linear-gradient(-90deg, rgba(73,47,106,1) 0%, rgba(73,47,106,0) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#492f6a",endColorstr="#492f6a",GradientType=1);
  }

  %shadow{box-shadow: 3px 3px 10px rgba(142, 155, 184, .65)!important;}

  %hasArrow{
   content: "";
   @include border-radius(3px);
   border:1px solid getColor(primaryGray);
   background: transparent;
   transform:rotate(45deg); -o-transform:rotate(45deg); -ms-transform:rotate(45deg);
   position: absolute;
   background: getColor(whiteColor);
   z-index: 1;
  }

%arrowitemHolderBorder{border:1px solid getColor(primaryGray); @include border-radius(5px)}

  %hasArrowRight{
   @extend %hasArrow; 
   border-left: 0;
   border-bottom: 0;
   border-top: inherit !important;
   border-right: inherit !important;
  }

  %hasArrowLeft{
   @extend %hasArrow; 
   border-right: 0;
   border-top: 0;
  }

  %hasArrowBottom{
   @extend %hasArrow; 
   border-left: 0;
   border-top: 0!important;
  }

  %hasArrowTop{
    @extend %hasArrow; 
    border-right: 0;
    border-bottom: 0;
    border-top: inherit !important;
   }

  %HoverTransition{
   transition:all .3s ease-in-out;
   -webkit-transition:all .3s ease-in-out;
   -moz-transition:all .3s ease-in-out;
   -o-transition:all .3s ease-in-out;
 }
 %noHoverTransition{
   transition:all 0s ease-in-out;
   -webkit-transition:all 0s ease-in-out;
   -moz-transition:all 0s ease-in-out;
   -o-transition:all 0s ease-in-out;
 }
 
 %text-overflow{
   text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
 }
 %no-text-overflow{
  text-overflow:inherit; white-space: inherit; overflow: inherit;
}
