.authModal {
  .modal-content {
    background: getColor(secondaryGray);
  }
  .modal-body {
    padding: 0;
  }
  .bannerAreaContainer {
    height: 100%;

    @include mobile {
      display: none !important;
    }
  }
  .bannerArea {
    height: 100%;
    .content {
      position: relative;
      height: 100%;
      width: 100%;
      .topContent {
        width: 60%;
        margin: 1.8rem 2.4rem;

        @include mobile {
          width: 100%;
        }
      }
      .heading {
        @include fontsize(18);
        font-family: $themefont-ExtraBold;
        line-height: 1.4;
      }
      .imageHolder {
        width: 280px;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          max-width: 100%;
        }
        height: auto;
      }
    }
  }
  &.signinModal {
    .modal-content {
      height: 455px;
    }

    .promotionalBanner {
      &.hasPurpleBg {
        @extend %purpleGradiant90Degree;
      }
      &.hasGreenBg {
        background: #b7cc3b;
        .title,
        .subTitle {
          color: getColor(secondaryPurple);
        }
      }
      &.hasPinkBg {
        @extend %pinkGradiant-90Degree;
      }
      @include fontsize(21);
      color: getColor(whiteColor);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &.signupModal {
    .modal-content {
      height: 600px;
      @include mobile {
        height: auto;
      }
    }
    .promotionalBanner {
      &.hasPurpleBg {
        @extend %purpleGradiant90Degree;
      }
      &.hasGreenBg {
        background: #b7cc3b;
        .title,
        .subTitle {
          color: getColor(secondaryPurple);
        }
      }
      &.hasPinkBg {
        @extend %pinkGradiant-90Degree;
      }
      @include fontsize(21);
      color: getColor(whiteColor);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .signin_signupArea {
    padding: 2rem;
    @include mobile {
      padding: 1.2rem;
    }
    .signin_signupAreaHeading {
      margin-bottom: 1.2rem;
      color: getColor(secondaryPurple);
      @include fontsize(14);
      font-family: $themefont-Bold;
    }
  }
}

.closeModalContainer {
  position: absolute;
  right: 5px;
  top: 0px;
  @include fontsize(13);
  color: getColor(fourthGray);
  cursor: pointer;
  z-index: 50;
  &:hover {
    color: getColor(secondaryPink);
  }
}
