@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
@import "../../assets/scss/placeholders";
.productSlider{
    background-color: getColor(whiteColor); border:2px solid getColor(whiteColor); @include border-radius(5px); @extend %shadow;
    height: 420px;
    @include mobile{height: auto;}
    > div{height: 100%;}
    .productSliderHeadingContainer{
        @extend %whiteGradiantMinus90Degree; @include flexbox(); @include align-items(center); @include justify-content(center);
        &.promotion{
            @extend %blueGradiant45Degree;
            @include mobile{
                background: rgb(255,255,255) !important;
                background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%) !important;
                background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%) !important;
                background: linear-gradient(60deg, rgba(255,255,255,1) 0%,rgba(231,235,242,1) 20%,#6cbabb 65%,#00a9ac 80%) !important;
        }
            .heading{
                color: #004669 !important;
            }
            .promotionButton{
                &:hover{
                    color: #004669 !important;
                }
              
            }
            &:after{
                content: '';
                @extend %hasArrowRight;
                right: -12px;
                width: 24px;
                height: 24px;
                background: #6cbabb !important;
                @include mobile{
                    bottom: -11px;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    background: #b0d4d4 !important;
                }
            }
        }
        position:relative; z-index: 2; height: 100%;
        @include flex-direction(column); @include border-radius(5px); padding-right:2rem; padding-left: 2rem;
        @include mobile{
                background: rgb(255,255,255) !important;
                background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%) !important;
                background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%) !important;
                background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,235,242,1) 100%) !important;
        }
        .heading{font-family: $themefont-Medium; @include fontsize(15); 
            text-align: center; line-height: 1.2;}
        &:after{
            content: '';
            @extend %hasArrowRight;
            right: -12px;
            width: 24px;
            height: 24px;
            // 00a9ac
            background: #e8ecf3 !important;
            @include mobile{
                bottom: -11px;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
        &.hasThemeBg{
            background: getColor(secondaryPink) !important; color: getColor(whiteColor) !important;
            &:after{
                background: getColor(secondaryPink) !important;
            }
        }
        @include mobile{
            padding:2rem;
        }
    }
    .productSliderContainer{ padding:0;
        .sliderWrapper{
            position:relative; height: 100%;
        }
    }
    .swiper-container{ padding-bottom: 3%; height: 103%;
        @include mobile{
            padding-bottom: 0;
            height: 100%;
        }
        .swiper-scrollbar{
            .swiper-scrollbar-drag{background: getColor(primaryGray);}
            @include mobile{
                display: none;
            }
        }
    }
    &.sliderBottomSpacing{
        margin-bottom: 50px;
        @include mobiletablet{ margin-bottom: 1.4rem;}
    }
}