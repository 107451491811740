.alert {
    &.alert-light{
        &.transparent{background: none !important; padding-right: 0; padding-left: 0; border:0}
    }
    &.alert-purple{
        background: getColor(primaryPurple, semi-light) !important; color: getColor(whiteColor);
    }
    &.alert-pink{
        background: getColor(secondaryPink, semi-light) !important; color: getColor(whiteColor);
    }
    &.alert-green{
        background: getColor(primaryGreen, semi-light) !important; color: getColor(whiteColor);
    }
    &.alert-yellow{
        background: getColor(primaryYellow, semi-light) !important; color: getColor(darkColor);
    }
    .alertHeading{
        color: getColor(secondaryGreen); font-family: $themefont-Bold; @include fontsize(11);
    }
    .alertBody{font-family: $themefont-Book;}
}