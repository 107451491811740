.breadcrumb{
   
    background: transparent !important; margin-bottom: 0; margin-top: 1.4rem;
    .breadcrumb-item{color: getColor(secondaryPurple); text-transform: uppercase; @include fontsize(13);
        font-family: $themefont-Medium;
        a{
            @include mobile{
                font-size: 15px;
            }
            color: getColor(secondaryPurple); text-decoration: none;
            &:hover{text-decoration: underline;}
        }
        &::before{
            content: ">" !important;
            color: getColor(secondaryPurple);  font-family: $themefont-Medium;
        }
    &:first-child{
        &:before{
            content: "" !important;
        }
    }
    &:last-child{
        max-width: 70%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
        a{pointer-events: none; cursor: pointer; font-family: $themefont-ExtraBold;}
    }
    }
}