.placeOrderitem{
      .whatsupIcon{width: 60px; margin-bottom: 1rem;}
      .signinForm{
        .btn-whiteButton{
          color: getColor(whiteColor) !important; border-color:#7E356F;  @extend %purpleGradiant;
          &:hover{
              @extend %purpleGradiantHover; color: getColor(whiteColor) !important; border-color:#7E356F;
          }
        }
      }
    }