.formContainer{
    input{
        &.form-control{height: calc(1.9em + 0.75rem + 2px);}
    }
    select{
      height: calc(1.9em + 0.75rem + 2px);
    }
    label{font-family: $themefont-Book;}
    .errorParent {
      input[type="file"]{
      
        &::-webkit-file-upload-button {
          background: transparent;
          color: gray;
          padding: 0.4rem;
          border:1px solid getColor(dangerColor);
          border-radius:5px;
        }
      }
        input, textarea, select{
            border-color: getColor(dangerColor);
        }
    }
    .errorMessage{
      color:getColor(dangerColor); @include fontsize(8); margin-top: .3rem; display: inline-block; width: 100%;
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      /*backface-visibility: hidden;*/
      perspective: 100;
      &.positionAbsolute{
        position: relative; bottom: 18px;
      }
  }
    .charCount{ position: absolute; right: 0; margin-top: .4rem; @include fontsize(8); font-family: $themefont-Book; font-style: italic;}
    .form-group{margin-bottom: 1.3rem;}
    .form-text{
        &.text-danger{
            color:getColor(dangerColor) !important; @include fontsize(7); margin-top: .1rem; display: inline-block;
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            /*backface-visibility: hidden;*/
            perspective: 100; font-family: $themefont-Book; position: absolute;
        }
    }
      .form-row-full-mobile{
        .form-row{
          @include mobile{display: inline-block; width: 100%;}
        }
      }
      .form-row-full-smallmobile{
        .form-row{
          @include smallmobile{display: inline-block; width: 100%;}
        }
      }
      .form-row-full-table{
        .form-row{
          @include tablet{display: inline-block; width: 100%;}
        }
      }
      .form-row-full-touchDevices{
        .form-row{
          @include mobiletablet{display: inline-block; width: 100%;}
        }
      }
    .mendatory{
      label{ position: relative;
        &:after{
          content: '*';
          position: absolute;
          right: -9px; @include fontsize(13); top: -4px;
          height: 100%;
          color:getColor(secondaryPink);
        }
      }
    }
}

.forgotPassword{color: getColor(fourthGray); @include fontsize(8); font-family: $themefont-Book; margin-top: .6rem; display: block;white-space: nowrap;
  &:hover{color: getColor(secondaryPink);}
}

.customHeadingForForm{display: inline-block; width: 100%; margin-bottom: 1.6rem; @include fontsize(10);
  p{margin-bottom: 0;}
}
.successIcon{ margin-bottom: 20px;
  svg {
    width: 55px;
    display: block;
  }
  
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash .9s ease-in-out;
      animation: dash .9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash .9s .35s ease-in-out forwards;
      animation: dash .9s .35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check .9s .35s ease-in-out forwards;
      animation: dash-check .9s .35s ease-in-out forwards;
    }
  }
  
  p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
    &.success {
      color: #73AF55;
    }
    &.error {
      color: #D06079;
    }
  }
  
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
     &.center{
          svg{margin: 0 auto;}
      }
}


.additionalMsgAfterSubmission{
   text-align: center; @include fontsize(9); font-family: $themefont-Book; max-width: 680px; width: 100%; margin: 2.4rem auto 0 auto;
}

select{
  &.custom-select{
    background:#ffffff url('../../#{$themeimage-path}/arrow-dropdown.svg') 95% center no-repeat !important; 
    background-size: 12px 12px !important;
  }
}

.hidePlaceholder{
  &::-webkit-input-placeholder {
    color:transparent !important; opacity: 0 !important;
 }
 
 &:-moz-placeholder { /* Firefox 18- */
  color:transparent !important; opacity: 0 !important;
 }
 
 &::-moz-placeholder {  /* Firefox 19+ */
  color:transparent !important; opacity: 0 !important;
 }
 
 &:-ms-input-placeholder {  
  color:transparent !important; opacity: 0 !important;
 }
}


.mendatoryFieldMessage{ font-family: $themefont-Bold; @include fontsize(8); color: getColor(tertiaryPurple);
  .mendatoryMark{
    color:getColor(secondaryPink);
  }
}

.signin_signupArea{
  .formContainer{
    label{ position: relative;
      &:after{
        content: '*';
        position: absolute;
        right: -9px; @include fontsize(13); top: -4px;
        height: 100%;
        color:getColor(secondaryPink);
      }
    }
  }
  }

  .form-control{
    font-family: Arial, Helvetica, sans-serif; font-weight: normal; @include fontsize_imp(9); color: getColor(fourthGray);
  }

  .custom-select-lg{
    height: calc(1.4em + 1rem + 2px) !important;
  }

  .hideInputLabel{
    > .form-group{
      label{display: none;}
    }
  }

.form-group-p-0{
  .form-group{margin-bottom: 0rem;
    @include mobile{margin-bottom: 1rem;}
  }
}
.form-group-p-1{
  .form-group{margin-bottom: .5rem;
    @include mobile{margin-bottom: 1rem;}
  }
}



.intl-tel-input{ width: 100%;
  input{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
}


.radiocontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radiocontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radiocontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radiocontainer input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiocontainer .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}