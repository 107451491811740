/**** Rich Text Properties ********/
.richtext{
  line-height: 1.2; font-family: $themefont-Book;
   ul{	margin:0 0 1.6rem 1.6rem !important;
      li{ list-style:none; text-align:left; margin: 0px 0; font-family: $themefont-Book;
        &:first-child{margin-top: 0px;}
        &:last-child{margin-bottom: 0px;}
        &::before {
          content: "•";
          color: getColor(tertiaryPink); @include fontsize(16); margin-right: .7rem;
        }
      }
      }
      b, strong{font-weight:bold;}
      p{margin-top: .8rem; font-family: $themefont-Book;
        &:first-child{margin-top: 0px;}
        &:last-child{margin-bottom: 0px;}
      }
      u{text-decoration:underline;}
      i{font-style: italic;}
   a{color: getColor(secondaryPink);
   &:hover{
    color: getColor(tertiaryPink);
   }
 }
 h1,h2,h3,h4,h5{
   font-family: $themefont-Bold; @include fontsize(14); font-weight: normal; margin: 0 0 1rem 0; color: getColor(primaryPurple);
     + p{margin: .5rem 0;}
 }
}
