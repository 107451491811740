@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-150px);}
	60% {-webkit-transform: translateY(-5px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-15px);}
	60% {-moz-transform: translateY(-5px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-15px);}
	60% {-o-transform: translateY(-5px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-15px);}
	60% {transform: translateY(-5px);}
}

@keyframes shake-animation {
	0% { transform:translate(0,0) }
   1.78571% { transform:translate(5px,0) }
   3.57143% { transform:translate(0,0) }
   5.35714% { transform:translate(5px,0) }
   7.14286% { transform:translate(0,0) }
   8.92857% { transform:translate(5px,0) }
   10.71429% { transform:translate(0,0) }
   100% { transform:translate(0,0) }
 }