@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
@import "../../assets/scss/placeholders";
.btn{
    @include border-radius(10px !important);
    border-width:1px !important; border-style: solid; @include fontsize_imp(10);
    @include flexbox(); @include align-items(center); @include justify-content(center);
    &.hasRadius{@include border-radius(60px !important);}
    &.lessCorners{@include border-radius(3px !important);}
    &.btn-whiteButton{
        color: getColor(fourthGray); border-color:getColor(whiteColor);  @extend %btnWhiteGradiant;
        &:hover{
            @extend %btnWhiteGradiantHover; color: getColor(tertiaryPink); border-color:getColor(whiteColor);
        }
    }
    &.btn-PurpleButton{
        color: getColor(whiteColor); border-color:#7E356F;  @extend %purpleGradiant;
        &:hover{
            @extend %purpleGradiantHover; color: getColor(whiteColor); border-color:#7E356F;
        }
    }
    &.btn-PinkButton{
        color: getColor(whiteColor); border-color: getColor(tertiaryPink); @extend %pinkGradiant;
        &:hover{
            @extend %pinkGradiantHover; color: getColor(whiteColor); border-color: getColor(tertiaryPink);
        }
    }
    &.hasPadding{padding: 0.6rem 1.8rem !important;}
    &.hasExtraPadding{width: 170px;
        @include mobile{width: auto;}
    }
    &.hasShadow{@extend %shadow;}
    &.btn-white{background: getColor(whiteColor); 
        &:hover{background: getColor(secondaryGray);}
    }
    &.hasCaret{padding-right: 36px !important;
        &:after{
            content: '';
            position: absolute;
            top: 15px;
            right: 10px;
            border-left: 7px solid getColor(tertiaryPink);
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
          }
    }
    &.noHover{cursor: default !important;}
    &.btn-whiteButtonActive{
        border-color:getColor(whiteColor);  @extend %btnWhiteGradiant;
    }
    &.btn-PurpleButtonActive{
        border-color:#7E356F;  @extend %purpleGradiant;
    }
    &.btn-PinkButtonActive{
        border-color: getColor(tertiaryPink); @extend %pinkGradiant;
    }
}

.btn-sm,
.btn-group-sm >
.btn{
    padding: 0.3rem 1rem !important; font-size: 85%!important;
    &.hasPadding{padding: 0.3rem 1rem !important;}

}

.buttonContainer{
    button, div, li, a{
        &:first-child{
            margin-right: 1.6rem;
            @include mobile{
                // margin: 0 0 1.6rem 0;
            }
        }
        &:last-child{
            margin-right: 0;
        }
    }
    &.center{
        @include justify-content(center);
    }
    &.inheritAlign-xs{
        @include mobile{
            @include justify-content(inherit)
        }
    }
}