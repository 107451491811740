.modal-backdrop{
    background-color: getColor(secondaryPurple) !important;
    &.show{ opacity: .8 !important;}
}

.modal-dialog{
    &.modal-lg, &.modal-xl{
        max-width: 900px;
    }
}

.customModal{
    .modal-body{
        padding: 1.4rem 2rem 2rem 2rem;
    }
}