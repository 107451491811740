// =============================================================================
// Variables
// =============================================================================
$themefont-path: "assets/fonts/";
$themeimage-path: "./assets/images/";
$themefont-path-deap: "../../assets/fonts/";
$themeimage-path-deap: "../../assets/images/";
$themefont-Medium: AttenRoundNewMedium;
$themefont-Bold: AttenRoundNewBold;
$themefont-ExtraBold: AttenRoundNewExtraBold;
$themefont-Book: AttenRoundNewBook;
$themefont-arial: Arial, Helvetica, sans-serif;
$theme-colors: (
  primaryPink: #D81172,
  secondaryPink: #DA0072,
  tertiaryPink : #7F3370,
  primaryPurple: #492F6A,
  secondaryPurple: #492D6B,
  tertiaryPurple: #4A5265,
  primaryYellow  : #B7CE29,
  primaryGreen : #72AA1B,
  secondaryGreen : #080808,
  blue: #1F3C8B,
  primaryGray: #D1D5E2,
  secondaryGray: #E7EBF2,
  tertiaryGray: #646E87,
  fourthGray: #8E9AB9,
  whiteColor: #ffffff,
  darkColor:#303030,
  successColor: #72AA1B,
  dangerColor: #D81172,
);

@function getColor($color, $variant: null){
  $color: map-get($theme-colors, $color);
  @return map-get((
    extra-light: mix($color, white, 25%),
    light:       mix($color, white, 50%),
    semi-light:  mix($color, white, 75%),
    semi-dark:   mix($color, black, 75%),
    dark:        mix($color, black, 50%),
    extra-dark:  mix($color, black, 25%)
  ),$variant) or $color;
}


// $primaryPink  : #D81172,
// $secondaryPink: #DA0072,
// $tertiaryPink : #7F3370,
// $primaryPurple: #492F6A,
// $secondaryPurple: #492D6B,
// $primaryGreen  : #B7CE29,
// $secondaryGreen : #72AA1B,
// $tertiaryGreen : #63AD68,
// blue  : #1F3C8B,
// primaryGray: #a8a9ae,
// secondaryGray: #000000,
// tertiaryGray: #8c9091,
// whiteColor: #ffffff,
// darkColor:#000000,
// successColor: #72AA1B,
// dangerColor: #D81172,

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1280px
) ;

$headerOverlayWidth: 430px;
$headerOverlayVerticle: 70px;
$headerOverlayHorizontal: -198px;
$themeBorderColor: rgba(142, 154, 185, 0.2);
$itemHolderSpacing: 1.4rem 1.4rem .5rem 1.4rem;
$quickViewTriangleMeasurment: 21px;
$detailsPageContainersTopPadding: 3.2rem;
$elementSpacingBottomMobile: 2rem;
$themeBorderRadius: 5px;
@import '~bootstrap/scss/bootstrap';