
.innerpageCard{
    background: getColor(whiteColor); padding:2.4rem !important; margin: 2.4rem auto; @include border-radius(5px);
    &.hasMarginTop{ margin-top: -100px;
        @include mobile{
            margin-top: 2rem;
        }
    }
    @include mobile{padding:1rem!important; margin-top: 2rem; margin-bottom: 2rem;}
    @include tablet-landscape{margin: -100px auto 2.4rem auto !important;}
    &.prLess{padding: 2.4rem 1rem 2.4rem 2.4rem!important;
        @include mobile{padding:1rem 1.2rem !important;}
    }
}

.pageHeading{
    font-family: $themefont-ExtraBold; @include fontsize(14); color: getColor(tertiaryPink);
    &.lg{ @include fontsize(18)}
    &.xl{ @include fontsize(21)}

}

.sectionHeading{
    font-family: $themefont-ExtraBold; @include fontsize(10); color: getColor(secondaryPink);
}

.cardBody{
    background: getColor(whiteColor); padding:1rem; @include border-radius(10px);
}