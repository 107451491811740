.customPopoverTheme{
    padding:.5rem; background-color: getColor(secondaryGray) !important; @include border-radius(5px !important); border:0 !important;
    // transform: translate(-30px, 36px) !important;
    max-width: inherit; width: 300px;
    cursor:default;
    .list-group-item{
        background: transparent; font-family: $themefont-Medium; color: getColor(tertiaryGray); @include fontsize(10);
        border-bottom-width: 2px !important; border-color: rgba(getColor(fourthGray), .2); padding:.875rem;
        &:last-child{border-bottom:0;}
    }
    .arrow{
        transform:rotate(45deg)!important; background: getColor(secondaryGray); width: 22px !important; height: 22px !important; @include border-radius(3px);
        margin: 0 auto!important; right: 0;
        &::after,
        &::before
        {
            border-bottom-color: getColor(secondaryGray)!important; display: none !important;
        }
        @include mobile{display: none !important;}
    }
    &.whitePopover{
        background-color: getColor(whiteColor) !important;
        .arrow{
            background: getColor(whiteColor); 
            &::after,
            &::before
            {
                border-bottom-color: getColor(whiteColor)!important;
            }
            @include mobile{display: none !important;}
        }
    }
    .notificationIcon{color:getColor(primaryPurple); @include fontsize(12);}
    &.notificationPopover{
        max-width: 400px; max-width: inherit; transform: translate(-135px, 36px) !important; padding:0;
        // @include mobile{width: 280px; transform: translate(-20%, 36px) !important; }
        @include mobile{
            width: 95%;
            position: fixed !important;
            left: 0 !important;
            right: 0 !important;
            top: 0 !important;
            margin: .6rem auto !important;
            transform: inherit !important;
        }
        ul{
            li{
                padding: 1rem .6rem .6rem .6rem; position: relative; line-height: 1.3;
                &:first-child{padding-top: 0;}
                &:last-child{padding-bottom: 0;}
                &.highPriority{
                    .notificationIcon{color:getColor(secondaryPink);}
                }
            }
        }
        
        .dismissNotificationHolder{
            display: inline-block; width: 100%;
            .dismissNotification{
                @include fontsize(6); font-family: $themefont-Medium; text-transform: uppercase; cursor: pointer;
                @include flexbox(); @include align-items(center); float: right;
                &:hover{color: getColor(secondaryPink);}
            }
        }
        .arrow{
            @include mobile{
                // right: inherit; left: 20% !important;
                display: none;
            }
        }
    }
}

.overlayItemIconHolder{position: relative; display: inline-block;
    .customBadge{
        position: absolute;
        top: -5px;
        border-radius: 100% !important;
        width: 17px;
        height: 17px; @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include fontsize(7);
        &.left{
            left: -6px;
        }
        &.right{
            right: -6px;
        }
    }
}

.overlayTitle{@include fontsize(11); margin-bottom: .8rem; color: getColor(secondaryPurple);
    @include mobile{
        padding-bottom: .6rem; border-bottom:3px solid $themeBorderColor;
    }
}