
@import "../../assets/scss/mixin";
.buttonContainer{
    align-items: center;
    @include mobile{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
    }
    .textright{
        text-align:right;
        @include mobile{
            text-align:left;
            margin:0.95rem  0;
        }
    }
}