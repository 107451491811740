@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
.swiper-container {
  &.noOverflow {
    overflow: inherit !important;
  }
  &.clearfix:before,
  &.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
  }
  &.clearfix:after {
    clear: both;
  }
  &.clearfix {
    zoom: 1;
  } /* IE < 8 */
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      @include border-radius(3px);
      border: 1px solid getColor(whiteColor);
      background: transparent;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: getColor(whiteColor);
    }
  }
  &.swiper-container-vertical {
    > .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        margin: 1rem 0;
      }
    }
  }
  .hasLink {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 26px;
    height: 26px;
    @include border-radius(5px);
    border: 1px solid getColor(whiteColor);
    background: getColor(primaryGray);
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    &:after {
      content: "";
      width: 0;
      height: 0;
    }
  }
  .swiper-button-next {
    &:after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid getColor(whiteColor);
      transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
    }
  }
  .swiper-button-prev {
    &:after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid getColor(whiteColor);
      transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
    }
  }
  .swiper-scrollbar {
    background: none !important;
    bottom: 12px !important;
    .swiper-scrollbar-drag {
      background: getColor(whiteColor);
      height: 9px;
    }
  }
}

@media screen and (min-width: 320px) {
  .swiper-container {
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .swiper-container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .swiper-container {
    width: 100%;
  }
}

@media screen and (min-width: 1366px) {
  .swiper-container {
    width: 100%;
  }
}

.customSliderNavigation {
  width: 26px;
  height: 26px;
  @include border-radius(5px);
  border: 1px solid getColor(whiteColor);
  background: getColor(primaryGray);
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  margin: 0.8rem 0;
  position: absolute;
  right: -12px;
  cursor: pointer;
  z-index: 5;
  &.promotion{
    &:hover {
      background: #00a9ac!important;
    }
  }
  &:hover {
    background: getColor(secondaryPink);
  }
  &.next {
    top: 40%;
    .customSliderNextIcon {
      width: 18px;
      height: 18px;
      color: getColor(whiteColor);
      transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
    }
  }
  &.prev {
    top: 26%;
    .customSliderPrevIcon {
      width: 18px;
      height: 18px;
      color: getColor(whiteColor);
      transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
    }
  }
  &.swiper-button-disabled {
    display: none !important;
  }
  @include mobile {
    display: none;
  }
}
