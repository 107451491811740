.link{ cursor: pointer;
    &.green{color: getColor(secondaryGreen);
        &:hover{ color: getColor(primaryGreen);}
    }
    &.pink{color: getColor(secondaryPink);
        &:hover{ color: getColor(tertiaryPink);}
    }
    &.purple{color: getColor(primaryPurple);
        &:hover{ color: getColor(tertiaryPink);}
    }
    &.white{color: getColor(whiteColor);
        &:hover{ color: getColor(secondaryGray);}
    }
    &.dark{color: getColor(tertiaryGray);
        &:hover{ color: getColor(darkColor, dark);}
    }
    &.gray{color: getColor(fourthGray);
        &:hover{ color: getColor(fourthGray, dark);}
    }
    &.semidark{color: getColor(fourthGray);
        &:hover{ color: getColor(darkColor);}
    }
    &.extradark{color: getColor(darkColor, semi-dark);;
        &:hover{ color: getColor(darkColor, extra-dark);}
    }
    &.underline{
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}