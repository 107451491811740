

.itemHolder {
  
  padding: $itemHolderSpacing;
  border-right: 3px solid $themeBorderColor;
  position: relative;
  height: 100%;

  &:hover {
    background: getColor(secondaryGray, light);

    .itemImage {
      border: 3px solid $themeBorderColor;
      // -ms-transform: scale(1.1); /* IE 9 */
      // -webkit-transform: scale(1.1); /* Safari 3-8 */
      // transform: scale(1.1);
    }
  }

  &.noBorder {
    border: 0;
  }
  &.noHover {
    &:hover {
      background: none;
      .itemImage {
        border: 0;
      }
    }
  }
  @include mobile {
    border-top: 3px solid $themeBorderColor;
    border-right-width: 0;
   
  }
  .itemImageHolder {
    text-align: center;
    height: 160px;
    // @include mobile{min-height:280px;}
    @include flexbox;
    @include justify-content(center);
    width: 100%;
    a {
      width: 100%;
    }
    position: relative;
    &.autoHeight {
      height: auto;
    }
    @include mobile {
      height: auto;
    }
    .itemImageWrapper{
      position: relative;
      display: inline-block;
    }
    .itemImage {
      max-height: 160px;
      max-width: 100%;
      margin: 0 auto;
      transition: transform 0.2s;
      @include mobile {
        height: auto;
        max-height: 300px;
        // max-height: inherit;
      }
    }
    .starburst {
      top: 3%;
      right: -4%;
    }
    .isCustomizableBadge {
      position: absolute;
      top: -0.4rem;
      z-index: 10;
    }
    .outofStockBadge{
      position: absolute;
      font-weight: normal!important;
      font-size: 12px!important;
      right: 0.27rem;
      bottom: 0.27rem;
    }
  }
  > .itemDetails {
    font-family: Arial, Helvetica, sans-serif;
    .itemCategory {
      color: getColor(secondaryGreen);
      @include fontsize(7);
      text-transform: uppercase;
      font-weight: bold;
      p {
        margin: 0;
      }
      > a {
        padding-top: 1.1rem;
        padding-bottom: 0.6rem;
      }
    }
    .itemTitle {
      @include fontsize(8);
      color: getColor(tertiaryGray);
      overflow: hidden;
      height: 55px;
      padding-bottom: 0.5rem;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: normal;
      @include mobile {
        height: auto;
        max-height: inherit;
        overflow: inherit;
      }
    }
    ul {
      li {
        margin: 0;
        &:last-child {
          margin-top: 0.6rem;
        }
      }
    }
    a {
      color: inherit;
      display: block;
      cursor: pointer;
      height: 100%;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &.horizontalItemHolder {
    
    @include flexbox();
    padding: 0.6rem 0 !important;
    border-right: 0;
    border-top: 0;
    width: 100%;
    &:hover {
      background: none;
    }
    .itemImageHolder {
      height: auto;
      margin-right: 0.8rem;
      @include flex(1);
      max-width: 145px;
      @include mobiletablet {
        width: 25%;
      }
      .itemImage {
        max-height: 100px;
        max-width: 124px;
        margin: 0 auto;
        width: 100%;
        height: auto;
      }
    }
    .addonContainer{
    
      .addOnul{
        @include mobile{
          flex-direction:column;
          
        }
      }
    }
    .itemDetails {
      margin-top: 0;
      @include flex(2);
      padding-right: 0.5rem;
      @include mobiletablet {
        width: 65%;
      }
      .itemTitle {
        @include fontsize(8);
        color: getColor(tertiaryGray);
        overflow: hidden;
        height: 35px;
        padding-bottom: 0.2rem;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        @include mobile {
          height: auto;
          max-height: inherit;
          overflow: inherit;
        }
      }
      .itemCategory {
        color: getColor(secondaryGreen);
        @include fontsize(8);
        text-transform: uppercase;
        font-weight: bold;
        p {
          margin-bottom: 0.4rem;
          @include mobile{margin-bottom:0.2rem;}
        }
        > a {
          padding-top: 1.1rem;
          padding-bottom: 0.6rem;
        }
      
        
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  .quickView {
    position: absolute;
    cursor: pointer;
    @extend %HoverTransition;
    z-index: 1;
    &:before {
      @extend %HoverTransition;
      content: "";
      position: absolute;
      top: auto;
      left: 0;
      border-left: $quickViewTriangleMeasurment solid getColor(primaryYellow);
      border-right: $quickViewTriangleMeasurment solid transparent;
      border-top: $quickViewTriangleMeasurment solid getColor(primaryYellow);
      border-bottom: $quickViewTriangleMeasurment solid transparent;
    }
    .quickViewContent {
      z-index: 1;
      position: relative;
      text-align: center;
      .quickViewIcon {
        color: getColor(whiteColor);
        @include fontsize(9);
        margin: 0.1rem 0 0 0.35rem;
      }
    }
    &:hover {
      &:before {
        border-left: $quickViewTriangleMeasurment solid getColor(primaryGreen);
        border-right: $quickViewTriangleMeasurment solid transparent;
        border-top: $quickViewTriangleMeasurment solid getColor(primaryGreen);
        border-bottom: $quickViewTriangleMeasurment solid transparent;
      }
    }
  }
  .quickViewContainer {
    background: getColor(blue);
    padding: 1.2rem;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: $itemHolderSpacing;
    left: $itemHolderSpacing;
    bottom: $itemHolderSpacing;
    top: $itemHolderSpacing;
    border-radius: 5px;
    .quickviewItemImage {
      width: 80px;
      height: 80px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    ul {
      li {
        @include fontsize(8.5);
        text-transform: capitalize;
        display: inline-block;
        width: 100%;
        margin: 0;
        border-bottom: 1px solid $themeBorderColor;
        padding: 0.35rem 0;
        &:last-child {
          border: 0;
        }
        .heading {
          color: getColor(primaryYellow);
          font-family: $themefont-Bold;
          text-align: left;
          @include flex(1);
          @extend %text-overflow;
          margin-bottom: 0.2rem;
        }
        .content {
          color: getColor(whiteColor);
          font-family: $themefont-Book;
          text-align: right;
          @include flex(1);
          text-align: right;
          @extend %text-overflow;
        }
        &:last-child {
          padding-bottom: 0;
        }
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }

  .productItemActionButtons {
    > ul {
      @include flexbox();
      @include align-items(center);
    
      > li {
        @include tablet{
          margin: 0.12rem;
        }
        margin: 0.25rem 0.5rem 0 0.5rem;
        &:first-child {
          @include flex(1);
          @include tablet{
            padding-right: 0.1rem;
          }
          padding-right: 0.7rem;
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
          margin-top: 0.1rem;
        }
      }
    }
  }
  .react-rater {
    display: block;
    cursor: pointer;
    .react-rater-star {
      &.is-disabled {
        cursor: pointer;
      }
    }
  }
}
.price_font_xxsmall{
  font-size: 17px;
  
}
.price_font_xsmall{
  font-size: 19px;

}
.price_font_small{
  font-size: 20px;

}
.price_font_medium{
  font-size: 21px;
}
.price_font_large{
  font-size: 25px;
}

.itemPrice {
  // @include fontsize(15);

  color: getColor(secondaryPurple);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: inline-block;
  position: relative;

  .oldPrice {
    display: none;
    margin-left: 0.3rem;
  }
  &.isDiscountedprice {
    .oldPrice {
      display: block;
      @include fontsize(7);
      color: getColor(tertiaryGray);
      font-family: Arial, Helvetica, sans-serif;
      position: absolute;
      right: -10px;
      top: -13px;
    }
  }
  @include mobile {
    .price_font_xxsmall{
      font-size: 8px;
      
    }
    .price_font_xsmall{
      font-size: 9px;
    
    }
    .price_font_small{
      font-size: 10px;
    
    }
    .price_font_medium{
      font-size: 11px;
    }
    .price_font_large{
      font-size: 14px;
    }
  }
  @include tablet {
    .price_font_xxsmall{
      font-size: 15px;
      
    }
    .price_font_xsmall{
      font-size: 17px;
    
    }
    .price_font_small{
      font-size: 18px;
    
    }
    .price_font_medium{
      font-size: 19px;
    }
    .price_font_large{
      font-size: 21px;
    }
  }
}

.cartItemBottom {
  .priceContainer{
    @include mobile {
      .price_font_xxsmall{
        font-size: 12px;
        
      }
      .price_font_xsmall{
        font-size: 13px;
      
      }
      .price_font_small{
        font-size: 15px;
      
      }
      .price_font_medium{
        font-size: 17px;
      }
      .price_font_large{
        font-size: 20px;
      }
    }
    .price_font_xxsmall{
      font-size: 13px;
      
    }
    .price_font_xsmall{
      font-size: 15px;
    
    }
    .price_font_small{
      font-size: 15px;
    
    }
    .price_font_medium{
      font-size: 17px;
    }
    .price_font_large{
      font-size: 20px;
    }
  }
  margin-top: 0.4rem;
  > ul {
    @include flexbox();
    @include align-items(center);
    .priceContainer {
      @include flex(1);
      padding-right: 0.5rem;
    }
    li {
      margin: 0 !important;
      .cartItemActionButtons {
        @include flexbox();
        @include align-items(center);
        li {
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include mobiletablet {
  .shoppingCart,
  .checkout {
    .cartItemBottom {
      > ul {
        display: inline-block;
        width: 100%;
        > li {
          margin: 0.7rem 0 !important;
          &:first-child {
            margin-top: 0 !important;
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.additionalCostinCartItem {
  @include fontsize(9);
  font-family: $themefont-Bold;
  // background-color: rgba(188, 188, 188, 0.2);
  padding: 8px;
  border-radius: 10px;
  .additionalCost {
    color: getColor(secondaryPink);
    @include fontsize(11);
  }
}
.checkoutsection{
  display: block!important;
}

