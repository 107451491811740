.react-rater{ @include fontsize(14);
    .react-rater-star{
        &.is-active{color: getColor(fourthGray);}
    }
}

.productRating{
    &.xl{
        span{
            img{width: 32px;}
        }
    }
    &.lg{
        span{
            img{width: 26px;}
        }
    }
    &.md{
        span{
            img{width: 22px;}
        }
    }
    &.sm{
        span{
            img{width: 18px;}
        }
    }
    &.xs{
        span{
            img{width: 14px;}
        }
    }
}

input.hidden[type="radio"]{
        display: none !important;
    }
