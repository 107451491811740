
.dropzoneContainer{
    .dropzone-wrapper{
        // @include flexbox(); @include align-items(center); @include flex-direction(column); @include flex(1);
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
    }
    .clear-button{
    @include border-radius(5px !important);
    border-width:1px !important; border-style: solid; @include fontsize_imp(9);
    @include flexbox(); @include align-items(center); @include justify-content(center);
    color: getColor(whiteColor); border-color:#7E356F;  @extend %purpleGradiant;
        &:hover{
            @extend %purpleGradiantHover; color: getColor(whiteColor); border-color:#7E356F;
        }
    }
    .custom_file_upload{

        &::-webkit-file-upload-button {
            color: getColor(tertiaryPink);
            border: 1px solid getColor(primaryGray);
            background: getColor(whiteColor);
            border-radius:5px;
            padding: 0.4rem;
            @extend %btnWhiteGradiant;
          
          }
    }
}
  
