.customTab{
    .nav-pills{
      @include mobile{
        display: inline-block; width: 100%;
      }
      .nav-item, .nav-link{
        background: transparent !important; border-width:1px; border-style:solid; border-color: getColor(fourthGray); padding:.3rem 1.4rem; height: 100%;
        @include fontsize(7); position: relative; color: inherit; margin-right: .4rem;
        @include mobile{
          margin: .5rem 0;
        }
        &:hover{
          border-color: getColor(secondaryPink); color: getColor(secondaryPink);
        }
        &:last-child{margin-right: 0;}
        &.nav-link.active{
          border-color: getColor(secondaryPink); color: getColor(secondaryPink); @include fontsize(8);
          font-family: $themefont-Bold;
            &:after{
                @extend %hasArrowBottom;
                width: 13px; height: 13px; 
                right: 0px; left: 0; margin: 0 auto;
                top: calc(100% - .35rem);
                background: getColor(whiteColor);
                border-color: getColor(secondaryPink);
                border-width: 1px;
                @include mobile{
                  display:none;
                }
            }
        } 
      }
    }
    .tab-content{
      padding: 1.6rem 0 .5rem 0;
    }
    .tabContent{
      @include fontsize(9); font-family: $themefont-Book;
      b, strong{ font-family: $themefont-Bold;}
     
    }
    &.horizontal{
        .nav-item{padding:0; border: 0; margin-right: 0 !important;
            &:last-child{margin-bottom: 0;}
        }
        .nav-link{padding:1.6rem 1.2rem; background: getColor(secondaryGray) !important; margin-bottom: .5rem;
            @include fontsize(9); text-align: center; font-family: $themefont-Bold; border-width: 0;
            &:hover{
              border-color: getColor(fourthGray) !important;
              background: getColor(whiteColor) !important;
              color: getColor(tertiaryGray) !important;
              border-width: 1px;
            }
            &.hasExtraPaddingVerticle{padding-top: 2.2rem; padding-bottom: 2.2rem;
              &:hover{
                padding-top: 2.2rem; padding-bottom: 2.2rem;
              }
            }
            &.active{
              border-color: getColor(fourthGray) !important; background: getColor(whiteColor) !important;
              color: getColor(tertiaryGray) !important; @include fontsize_imp(9); border-width: 1px;
              &:after{
                  @extend %hasArrowRight;
                  width: 18px !important; height: 18px !important; 
                  right: -10px !important; margin: 0 auto; left:inherit !important;
                  top: calc(100% - 60%) !important;
                  background: getColor(whiteColor) !important;
                  border-color: getColor(fourthGray) !important;
                  border-width: 1px;

                  @include mobile{
                    display: none;
                }
                
              }
              &.hasExtraPaddingVerticle{
                &:after{top: auto !important; bottom: auto;}
              }
          }
        }
        .tab-content{
          @include tablet{
            margin-left: 0;
        }
          padding: 0; margin-left: 1.5rem;
          @include mobile{ margin-left: 0; margin-top: 1.2rem;}
        }
        &.horizontalTabThemeTwo{
          .nav-link{
            &.active{
              border-color: getColor(secondaryPink) !important;
              color: getColor(secondaryPink) !important; 
              &:after{
                  @extend %hasArrowRight;
                  border-color: getColor(secondaryPink) !important;
              }
            }
          }
        }
    }
    &.customTabThemeTwo{
      .nav-pills{
        .nav-item, .nav-link{
          border-color: getColor(tertiaryPink);
          &:hover{
            border-color: getColor(tertiaryPink); color: getColor(tertiaryPink);
          }
          &.nav-link.active{
            border-color: getColor(tertiaryPink); color: getColor(tertiaryPink);
              &:after{
                  background: getColor(whiteColor);
                  border-color: getColor(tertiaryPink);
              }
          } 
        }
      }
    }
    &.customTabStyle2{
      .nav-item{
        padding: 0; border: 0; height: auto !important;
        a{ height: auto !important;}
      }
    }
  }